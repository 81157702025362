import axiosInstance from "./AxiosInstance";
import { formattedDateString } from "./UtilsService";

export function getUsers(search = "", page = 0, limit = 10) {
  return axiosInstance.get(
    `/admin/users?search=${search}&page=${page}&limit=${limit}`
  );
}

export function getUserDetails(userId) {
  return axiosInstance.get(`/admin/userDetails?userId=${userId}`);
}

export function toggleBlockUser(userId, block) {
  const request = { userId: userId, block: !!block };
  return axiosInstance.put(`/admin/users`, request);
}

export function deleteUser(userId) {
  return axiosInstance.delete(`/admin/users?userId=${userId}`);
}

export function getUserTransactions(
  userId,
  type = "wallet",
  startDate,
  endDate,
  page = 0,
  limit = 10
) {
  if (!startDate) {
    startDate = "";
  } else {
    startDate = formattedDateString(startDate);
  }
  if (!endDate) {
    endDate = "";
  } else {
    endDate = formattedDateString(endDate);
  }
  return axiosInstance.get(
    `/admin/transactionHistory?userId=${userId}&startDate=${startDate}&endDate=${endDate}&type=${type}&page=${page}&limit=${limit}`
  );
}

export function rechargeUserWallet(userId, amount) {
  return axiosInstance.post("/admin/recharge", {userId, amount});
}

export function rechargeBirchCoins(userId, amount) {
  return axiosInstance.post("/admin/addBirchCoin", {userId, amount});
}

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setNoneAction } from "../../store/actions/BreadcrumbActions";
const MenuManagement = () => {
    const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(setNoneAction());
    // }, []);
    return (
        <>
            <div className="form-head d-flex mb-lg-4 mb-2 align-items-start">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Menu Management</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body pt-3">
                            <div className="table-responsive ">
                                <table className="table items-table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="media">
                                                    <div className="media-body">
                                                        <h3 className="mt-0 mb-1 font-w500">
                                                            <Link to="/menu/menus">
                                                                Menus
                                                            </Link>
                                                        </h3>
                                                        <h5 className="mt-4 mb-2">
                                                            <Link
                                                                className="text-black"
                                                                to="/menu/menus"
                                                            >
                                                                Manage items that app users can order
                                                            </Link>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="menu-arrow-box">
                                                <Link to="/menu/menus">
                                                    <div className="menu-arrow">
                                                        <i className="fs-32 las la-angle-double-right"
                                                        ></i>
                                                    </div>
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="media">
                                                    <div className="media-body">
                                                        <h3 className="mt-0 mb-1 font-w500">
                                                            <Link to="/menu/lists">
                                                                Modifiers
                                                            </Link>
                                                        </h3>
                                                        <h5 className="mt-4 mb-2">
                                                            <Link
                                                                className="text-black"
                                                                to="/menu/lists"
                                                            >
                                                                Manage add-ons to menu items
                                                            </Link>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="menu-arrow-box">
                                                <Link to="/menu/lists">
                                                    <div className="menu-arrow">
                                                        <i className="fs-32 las la-angle-double-right"></i>
                                                    </div>
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuManagement;
